/* project-settings-icon on hover change color with !important to white */
.project-settings-icon:hover {
  color: white !important;
}

.submit-file-button {
  margin-left: -70px;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.35);
  }
  100% {
    transform:  translateX(100%) scaleX(0.6);
  }
}

.slide-left-enter {
  transform: translateX(100%);
}
.slide-left-enter-active {
  transform: translateX(0%);
  transition: transform 300ms ease-in-out;
}
.slide-left-exit {
  transform: translateX(0%);
}
.slide-left-exit-active {
  transform: translateX(-100%);
  transition: transform 300ms ease-in-out;
}

.slide-right-enter {
  transform: translateX(-100%);
}
.slide-right-enter-active {
  transform: translateX(0%);
  transition: transform 300ms ease-in-out;
}
.slide-right-exit {
  transform: translateX(0%);
}
.slide-right-exit-active {
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}