.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68d391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68d391;
}

.modebar--hover {
  display: flex;
}

div.modebar-group {
  display: flex !important;
}

.modebar-group a {
  padding-bottom: 25px !important;
}

.files-table tr:nth-child(even) {
  background: #0D0E0F;
}

.legendtext {
  fill: #c4c4c4 !important;
}

.scrollbar-file-select::-webkit-scrollbar-thumb {
  background-color: white !important;
}

.scrollbar-file-select {
  max-height: calc(100% - 80px);
  height: fit-content;
}

.scroll-light::-webkit-scrollbar-thumb {
  background-color: white !important;
}

.scroll-light::-webkit-scrollbar-track {
  background-color: #434446;
}

/* .parent {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 400px;
} */

.childss {
  display: none;
  position: absolute;
  z-index: 99999;
}

.parentd:hover .childss {
  display: block;
}

.childss:hover .childss {
  display: block;
}

.rc-slider-mark-text {
  color: white !important;
}

#zoom-box {
  background-color: rgba(0, 0, 255, 0.3); /* Blue with transparency for the zoom box */
}

.cursor-crosshair {
  cursor: crosshair;
}

#image-container {
  position: relative;
}

#image-container::before {
  content: "";
  display: block;
  /* padding-top: 100%;  1:1 aspect ratio */
}

.file-list-container > div:not(:last-child) {
  border-bottom: 1px solid rgba(118, 120, 120, 1);
}