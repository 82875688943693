input[type="checkbox"].toggle {
  display: block;
}

input[type="checkbox"] {
  display: block;
}

.toggle-group-inputs {
  max-width: 40%;
}
.toggle-group-switch {
  max-width: 10%;
}

.toggle-group-switch .toggle::before {
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.toggle-group-switch .toggle::after {
  content: "";
  position: absolute;
  width: 14px;
  height: 26px;
  background: #39d456;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 1;
  left: -2px;
  cursor: pointer;
  border-radius: 3px;
  transition: 0.5s;
}

.toggle-group-switch .toggle:checked::before {
  background: rgba(255, 255, 255, 0.32);
}

.toggle-group-switch .toggle:checked::after {
  transform: translate(10px, -50%);
}
