body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="checkbox"] {
  /*opacity: 0;*/
  min-width: 20px;
  -webkit-appearance: none;
  position: relative;
  background: none;
  border: none;
}

input[type="checkbox"]::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  background: rgba(255, 255, 255, 0.32);
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  left: 50%;
  cursor: pointer;
  border-radius: 3px;
}

input[type="checkbox"]:checked::before {
  background: #39d456;
}

input:disabled {
  background: #434446;
  border-color: white;
  color: white;
  opacity: 0.4;
}

.custom-check-box {
}
