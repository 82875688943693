.progress-overlay {
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.8;
  display: flex;
  transition: opacity 0.2s ease;
}
